import styled from 'styled-components';
import { Link } from 'gatsby';

export const StyledBar = styled('div')`
  position: fixed;
  top: 100vh;
  transform: translateY(-100%);
  left: 0;
  width: 100%;
  z-index: 9998;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 23px -10px rgba(66, 68, 90, 0.6);
  ${({ theme }) => theme.mq.md} {
    align-items: center;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 20px 50px;
  }
`;

export const StyledLink = styled(Link)`
  font-size: 1.4rem;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.black};
  transition: 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.greenDark};
  }
`;

export const StyledTextWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  ${({ theme }) => theme.mq.md} {
    align-self: center;
  }
`;

export const StyledText = styled('span')`
  font-size: 1.4rem;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledAccept = styled('button')`
  padding: 8px 12px;
  text-align: center;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.greyDark};
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
`;
