import styled, { css } from 'styled-components';

export const StyledContactList = styled('ul')`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  ${({ inline }) =>
    inline &&
    css`
      flex-direction: row;
    `}
`;

export const StyledContactItem = styled('li')`
  list-style: none;
  padding: 0 1rem 2.8rem;
`;

export const StyledContactLink = styled('a')`
  color: ${({ theme }) => theme.colors.white};
`;
